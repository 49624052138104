import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

// MODELS
import {
  TCStatus, TCUpdateModel, ALERTS_ENUM, CompanyNameModel, LogSearchModel, LogsResponseModel, CompaniesResponseModel
  , SIZES
} from 'src/app/models';

// SERVICES
import { AlertService, LogsService, CompaniesService, StorageService } from 'src/app/services';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css'],
  providers: [DatePipe]
})
export class LogsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  statusList: any[];
  logsForm: FormGroup;
  currDate: Date;
  logsList: TCUpdateModel[] = [];
  logsSplice: TCUpdateModel[] = [];
  collectionSize: number;
  page: number;
  userCompaniesList: CompanyNameModel[] = [];
  companiesNames: string[] = [];
  sizes: any;
  pageSize: number; //Variables de paginacion

  constructor(private fb: FormBuilder,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private logService: LogsService,
    private companyService: CompaniesService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.onLoad();
  }

  onLoad() {
    this.logsForm = this.createLogsForm()
    this.companiesNames.length = 0;
    this.userCompaniesList.length = 0;
    this.InitVariables();
    this.GetCompaniesByUser();
  }

  createLogsForm() {
    return this.fb.group({
      // date: [{ value: this.datePipe.transform(this.currDate, 'yyyy-MM-dd'), disabled: false }, Validators.required],
      // company: [''],
      InitialDate: [{ value: this.datePipe.transform(this.currDate, 'yyyy-MM-dd'), disabled: false }, Validators.required],
      FinalDate: [{ value: this.datePipe.transform(this.currDate, 'yyyy-MM-dd'), disabled: false }, Validators.required],
      DbCode: [''],
      Status: [1, Validators.required]
    });
  }
  get FLogs() { return this.logsForm.controls; }

  InitVariables(): void {
    this.sizes = SIZES;
    this.statusList = TCStatus;
    this.currDate = new Date();
    this.logsList.length = 0;
    this.logsSplice.length = 0;
    this.collectionSize = 1;
    this.page = 1;
    this.pageSize = 15;
    this.logsForm.patchValue({
      InitialDate: this.datePipe.transform(this.currDate, 'yyyy-MM-dd'),
      FinalDate: this.datePipe.transform(this.currDate, 'yyyy-MM-dd'),
      DbCode: '',
      Status: 4
    });
  }

  // funcion que llama al service para traer las lineas o facturas asociadas al tramite cargado
  GetLogs() {
    this.logsList.length = 0;
    this.logsSplice.length = 0;
    const searhModel: LogSearchModel = {
      user: 0,
      dbCode: this.FLogs.DbCode.value,
      finalDate: this.FLogs.FinalDate.value,
      initialDate: this.FLogs.InitialDate.value,
      status: this.FLogs.Status.value,
    };
    this.logService.GetLogs(searhModel).subscribe({
      next: (response: LogsResponseModel) => {
        if (response.Result) {
          this.logsList = response.LogsList;
          this.page = 1;
          this.pageChange();
          this.alertService.ShowSmallCornerAlert(`Logs Obtenidos con Éxito!!!`, ALERTS_ENUM.SUCCESS);
        } else {
          this.alertService.ShowBigCenteredAlert(response.ErrorInfo.Message, ALERTS_ENUM.WARNING);
        }
      },
      error: (err: string) => {
        this.InitVariables();
        this.alertService.ShowBigCenteredAlert(`${err}`, ALERTS_ENUM.ERROR);
      }
    });
  }
  // funcion para obtener la nueva lista de compañías para mostrar en el front
  // no recibe parametros
  pageChange() {
    this.collectionSize = Math.ceil(this.logsList.length / this.pageSize) * this.pageSize;
    this.logsSplice.length = 0;
    this.logsSplice = this.logsList.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }


  // COMPAÑIAS

  // funcion para el typeahead
  searchCompany = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.companiesNames.filter(company => company.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  // funcion que llama al service para traer las lineas o facturas asociadas al tramite cargado
  GetCompaniesByUser() {
    this.companyService.GetCompaniesByUser().subscribe({
      next: (response: CompaniesResponseModel) => {
        if (response.Result) {
          this.userCompaniesList = response.Companies;
          this.GetUserCompaniesNames();
          this.alertService.ShowSmallCornerAlert(`Compañías del Usuario Obtenidas con Éxito!!!`, ALERTS_ENUM.SUCCESS);
        } else {
          this.logsList = [];
          this.alertService.ShowBigCenteredAlert(response.ErrorInfo.Message, ALERTS_ENUM.WARNING);
        }
      },
      error: (err: string) => {
        this.alertService.ShowBigCenteredAlert(`${err}`, ALERTS_ENUM.ERROR);
      }
    });
  }

  // funcion para inflar la lista que contiene los nombres de las compañias
  GetUserCompaniesNames() {
    this.companiesNames = this.userCompaniesList.map(x => x.Name);
  }

  // recorre la lista de compañias que tiene asignadas un usuarios y busca el nombre de la compañia filtradapor Id
  GetCompanyName(compId: number): string {
    return this.userCompaniesList.find(x => x.Id === compId).Name;
  }

  // obtener el status de una compañia filtrado por Id del status
  GetCompanyStatus(statusId: number): string {
    return this.statusList.find(s => s.id === statusId).status;
  }

  PageSizeChanged(size: number) {
    this.pageSize = size;
    this.page = 1;
    this.pageChange();
  }

}
