import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    private countRequest: number = 0;
    constructor(
        private loaderService: LoaderService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.countRequest) {
            this.loaderService.show();
        }
        this.countRequest++;
        return next.handle(request)
            .pipe(
                finalize(() => {
                    this.countRequest--;
                    if (!this.countRequest) {
                        this.loaderService.hide();
                    }
                })
            )
    }
}
