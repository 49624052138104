import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Services
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = this.authService.currentUserValue;
    const urlNavigatingTo = state.url.split('/')[1];

    if (currentUser) {
      if ((urlNavigatingTo === 'crud-connections' || urlNavigatingTo === 'crud-companies') && currentUser.UserName !== 'sys@clavisco.com') return false;

      return true;
    }

    this.authService.LogOut();
    return false;
  }

}