import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// SERVICIOS
import { StorageService } from './storage.service';
// MODELS
import { IUsersByCompanies, IUsersByCompaniesAll, IUsersByCompaniesAllResponseModel, IUsersByCompaniesResponseModel } from '../models/index';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UsersbycompaniesService {

  constructor(private http: HttpClient,
    private storage: StorageService) { }

  //Servicio par obtener Asignaciones
  GetAssignments(_user: string, _dbCode: string, _compName: string): Observable<IUsersByCompaniesAllResponseModel> {
    return this.http.get<IUsersByCompaniesAllResponseModel>(`${environment.ApiUrl}/api/Assignment/GetAssignments?User=${_user}&DbCode=${_dbCode}&CompName=${_compName}`);
  }

  //Servicio par obtener Usuarios
  GetUsers() {
    return this.http.get<any>(`${environment.ApiUrl}/api/Assignment/GetUsers`);
  }

  //Servicio para agregar nueva asignacion de usuario por compañia
  CreateUserByCompany(userbycompany: IUsersByCompanies) {
    return this.http.post<IUsersByCompaniesResponseModel>(`${environment.ApiUrl}/api/Assignment/CreateAssignment`, userbycompany);
  }

  //Servicio para Actualizar  asignacion de usuario por compañia
  UpdateteUserByCompany(userbycompany: IUsersByCompanies) {
    return this.http.post<IUsersByCompaniesResponseModel>(`${environment.ApiUrl}/api/Assignment/UpdateAssignment`, userbycompany);
  }

  //Servicio para Eliminar  asignacion de usuario por compañia
  DeleteUserByCompany(UserByCompId: Number) {
    return this.http.delete<IUsersByCompaniesResponseModel>(`${environment.ApiUrl}/api/Assignment/DeleteAssignment?UserByCompId=${UserByCompId}`);
  }
}
