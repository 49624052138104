import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';

// MODELS
import { ALERTS } from './../models/index';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  // funcion para mensaje de confirmación
  ShowBigCenteredAlert(message: string, alertId: number) {

    ALERTS.forEach(alert => {
      if (alert.Id === alertId) {
        this.BigCenteredAlert(message, alert.name);
      }
    });

  }


  // funcion para mensaje pequeño informativo
  ShowSmallCornerAlert(message: string, alertId: number) {

    ALERTS.forEach(alert => {
      if (alert.Id === alertId) {
        this.SmallCornerAlert(message, alert.name);
      }
    });

  }
  ConfirmationAlert(titulo: string, msg: string){
    let settings: SweetAlertOptions = {
      title: titulo,
      text: msg,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#008f39',
      cancelButtonColor: '#d33',
      confirmButtonText: "Eliminar",
      cancelButtonText: 'Cancelar'
    }
    return Swal.fire(settings);
  }
  

  // muestra el mensaje de alerta pequeño en la esquina de la pagina
  // recibe el mensaje que se quiere mostrar y el tipo de mensaje, ya sea success, error, warning, info
  private SmallCornerAlert(msn: any, tipo: any) {

    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });

    toast.fire({
      icon: tipo,
      text: msn
    });

  }

  // muestra el mensaje de alerta  en el centro de la pagina
  // recibe el mensaje que se quiere mostrar y el tipo de mensaje, ya sea success, error, warning, info
  private BigCenteredAlert(msn: any, tipo: any) {

    swal.fire({
      icon: tipo,
      text: msn
    });
  }

}