import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// SERVICIOS

// MODELS
import { IConnections, IConnectionsResponseModel } from '../models/index';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {

  constructor(
    private http: HttpClient
  ) { }

  //Servicio par obtener Conexiones
  GetConnections(Alias: string, SapUser: string) {
    return this.http.get<IConnectionsResponseModel>(`${environment.ApiUrl}/api/Connections/GetConnections?Alias=${Alias}&SapUser=${SapUser}`);
  }
  //Servicio para agregar nueva conexion
  CreateConnection(connection: IConnections) {
    return this.http.post<IConnectionsResponseModel>(`${environment.ApiUrl}/api/Connections/CreateConnection`, connection);
  }
  //Servicio para actualizar una Conexion
  updateConnection(connection: IConnections) {
    return this.http.post<IConnectionsResponseModel>(`${environment.ApiUrl}/api/Connections/UpdateConnection`, connection);
  }
}
