import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService, StorageService } from '../services';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

    private AUTH_HEADER = 'Authorization';

    constructor(
        private authService: AuthService,
        private dataStorageService: StorageService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // si se pide token se retorna el request de una vez
        if (req.url.includes('token')) return next.handle(req);

        // se agrega el content type 'application/json' al request
        if (!req.headers.has('Content-Type')) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            });
        }

        // se verifica que use autenticacion y de ser asi se evalua su estado y se agrega
        req = this.addAuthenticationToken(req);

        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            return event;
        }),
            catchError((error: HttpErrorResponse) => {
                const started = Date.now();
                const elapsed = Date.now() - started;
                console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
                return throwError(error);
            })
        );
    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        // If we do not have a token yet then we should not set the header.
        // Here we could first retrieve the token from where we store it.
        const TOKEN = JSON.parse(this.dataStorageService.getItem('currUser'));
        if (!TOKEN) {
            return request;
        }
        if (!this.authService.isAuthenticated()) {
            this.authService.LogOut();
            throwError(() => new Error('Token invalido'));
        }
        return request.clone({
            headers: request.headers.set(this.AUTH_HEADER, `Bearer ${TOKEN.access_token}`)
        });
    }

}