import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';


import { LogSearchModel, LogsResponseModel } from './../models/index';

// SERVICIOS
import { StorageService } from './storage.service';

// MODELS
import {  } from '../models/responses';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LogsService {

    constructor(private http: HttpClient,
                private storage: StorageService) { }

    // funcion que se comunica con el API para obtener logs de actualizacion de Tipo de Cambio
    GetLogs(searchModel: LogSearchModel) {

        const session = JSON.parse(this.storage.getItem('currUser'));
        searchModel.user = Number(session.UserId);

        return this.http.post<LogsResponseModel>(`${environment.ApiUrl}/api/Logs/GetLogs`, searchModel);
    }

}
