import { Component, OnInit } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, StorageService, AlertService } from 'src/app/services';
import { first } from 'rxjs/operators';
import { ALERTS_ENUM } from 'src/app/models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  userForm: FormGroup; // variable del formulario

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private auth: AuthService,
              private storageService: StorageService,
              private alertService: AlertService) { }

  ngOnInit() {
    // redirect to home if already logged in
    if (this.auth.currentUserValue) {
      this.router.navigate(['/home']);
    }
    this.userForm = this.fb.group({
      email: ['', [Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'), Validators.required]],
      password: ['', Validators.required]
    });
  }

  // llama al servicio para validar el inicio de sesion
  LogIn() {
    if (this.userForm.valid) {
      this.auth.LogIn(this.fUser.email.value, this.fUser.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.alertService.ShowSmallCornerAlert(` Logueado correctamente!!!`, ALERTS_ENUM.SUCCESS);
          this.router.navigate(['/home']);
        },
        error => {
          this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS_ENUM.ERROR);
        });
    }
  }

  // convenience getter for easy access to form fields
  get fUser() { return this.userForm.controls; }

}
