import { Injectable } from '@angular/core';
import { EnumList } from '../models/constants';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    constructor() { }

    ConvertEnumIntoList(enumType: any): EnumList[] { let map: EnumList[] = []; for (var n in enumType) { if (typeof enumType[n] === 'number') { map.push({ Value: <any>enumType[n], Name: n }); } } return map; }
}
