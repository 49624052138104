import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  // isLoading = new Subject<boolean>();
  @BlockUI() blockUI: NgBlockUI;

  constructor() { }

  public show(): void {
    this.blockUI.start('Procesando, Espere Por Favor...'); // Start blocking
    // this.isLoading.next(true);
  }

  public hide(): void {
    this.blockUI.stop();
    // this.isLoading.next(false);
  }
}
