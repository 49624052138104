import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorage;

  constructor(private router: Router) {
    this.localStorage = localStorage;
  }

  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setItem(item: any, itemName: string): void {
    this.localStorage.setItem(itemName, JSON.stringify(item));
  }

  // remueve la sesion actual
  removeItem(itemName: string): void {
    this.localStorage.removeItem(itemName);
  }

  // obtiene el token de la sesion actual
  getItem(itemName: string): string {
    return this.localStorage.getItem(itemName);
  }

  // deslogueo
  logout(): void {
    this.removeItem('currUser');
    this.router.navigate(['/login']);
  }
}
