import { RouterModule, Routes, CanActivate } from '@angular/router';

// COMPONENTS
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';

// GUARDS
import { AuthGuard } from './services';
import { LogsComponent } from './components/tc-logs/logs.component';
import { CrudCompaniesComponent } from './components/crud-companies/crud-companies.component';
import { CrudConnectionsComponent } from './components/crud-connections/crud-connections.component';
import { CrudUsersbycompaniesComponent } from './components/crud-usersbycompanies/crud-usersbycompanies.component';
import { CrudFinancialEntitiesComponent } from './components/crud-financial-entities/crud-financial-entities.component';

const APP_ROUTES: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'logs', component: LogsComponent, canActivate: [AuthGuard] },
    { path: 'crud-companies', component: CrudCompaniesComponent, canActivate: [AuthGuard]},
    { path: 'crud-connections', component: CrudConnectionsComponent, canActivate: [AuthGuard]},
    { path: 'crud-asignaciones', component: CrudUsersbycompaniesComponent, canActivate: [AuthGuard]},
    { path: 'crud-financial-entities', component: CrudFinancialEntitiesComponent, canActivate: [AuthGuard]},
    { path: 'login', component: LoginComponent},
    { path: '**', pathMatch: 'full', redirectTo: '' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);

