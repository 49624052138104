import { Component, OnInit } from '@angular/core';
import { ALERTS_ENUM, IFinancialEntityModel, IFinancialEntityResponseModel } from 'src/app/models';
import { AlertService } from 'src/app/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FinancialEntitiesService } from 'src/app/services/financial-entities.service';
import { forkJoin, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


@Component({
  selector: 'app-crud-financial-entities',
  templateUrl: './crud-financial-entities.component.html',
  styleUrls: ['./crud-financial-entities.component.css']
})
export class CrudFinancialEntitiesComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  financialEntitiesList: IFinancialEntityModel[] = [];
  filteredFinancialEntities: IFinancialEntityModel[] = [];

  BankNamesList: string[] = [];


  page: number = 1;
  pageSize: number = 10;
  collectionSize: number;

  searchForm: FormGroup;

  financialEntityForm = this.formBuilder.group({
    FinancialEntityId: [0, Validators.required],
    BankName: ['', Validators.required],
    BankTcCodeSale: ['', Validators.required],
    BankTcCodePurchase: ['', Validators.required],
    Status: ['', Validators.required],
  });

  isUpdating: boolean;
  subtitle: string;

  constructor(
    private financialEntitiesService: FinancialEntitiesService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }


  ngOnInit() {
    this.onLoad();
  }


  onLoad(): void {
    this.filteredFinancialEntities.length = 0;
    this.searchForm = this.createSearchForm();
    this.loadInitialData();
  }


  get getfinancialEntityForm() { return this.financialEntityForm.controls; }


  InitVariables(): void {
    this.financialEntitiesService.GetFinancialEntities('', 'v').subscribe(response => {
      this.financialEntitiesList = response.FinancialEntities;
      this.page = 1;
      this.pageChange();
    });
    this.searchForm.setValue({
      Value: '',
      Status: '',
    })
    this.financialEntityForm.setValue({
      FinancialEntityId: 0,
      BankName: '',
      BankTcCodeSale: '',
      BankTcCodePurchase: '',
      Status: '',
    }
    );
  }


  loadInitialData() {
    this.financialEntitiesList.length = 0;
    let financialEntities$ = this.financialEntitiesService.GetFinancialEntities('', 'v');
    forkJoin(
      [financialEntities$]
    ).subscribe((results) => {
      let sms: string = '';
      let _finEntResp = results[0];
      if (_finEntResp) {
        if (_finEntResp.Result) {
          this.financialEntitiesList = _finEntResp.FinancialEntities;
          this.BankNamesList = _finEntResp.FinancialEntities.map(x => x.BankName);
          this.page = 1;
          this.pageChange();
        } else {
          sms = `${_finEntResp.ErrorInfo.Message}`;
        }
      }
      if (sms.length > 0) {
        this.alertService.ShowSmallCornerAlert(`${sms}`, ALERTS_ENUM.ERROR);
      }
    });
  }


  createSearchForm() {
    return this.formBuilder.group({
      Value: [''],
      Status: ['v']
    });
  }


  GetFinancialEntities(isFilter: Boolean): void {
    this.financialEntitiesList.length = 0;
    this.filteredFinancialEntities.length = 0;
    this.financialEntitiesService.GetFinancialEntities(
      this.searchForm.value.Value,
      this.searchForm.value.Status,
    ).subscribe(
      {
        next: (response: IFinancialEntityResponseModel) => {
          if (response.Result) {
            this.financialEntitiesList = response.FinancialEntities
            this.page = 1;
            this.pageChange();
          } else {
            this.alertService.ShowSmallCornerAlert(`${response.ErrorInfo.Message}`, ALERTS_ENUM.WARNING);
          }
        }, error: error => {
          this.alertService.ShowSmallCornerAlert(`${error}`, ALERTS_ENUM.ERROR);
        }
      }
    )
  }


  searchBankName= (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.BankNamesList.filter(financialEntity => financialEntity.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
    

  selectedBankName($event) {
    this.searchForm.controls['BankName'].patchValue($event.item.BankName);
  }


  pageChange() {
    this.collectionSize = Math.ceil(this.financialEntitiesList.length / this.pageSize) * this.pageSize;
    this.filteredFinancialEntities.length = 0;
    this.filteredFinancialEntities = this.financialEntitiesList.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }


  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }


  createFinancialEntity(content: any) {
    this.isUpdating = false;
    this.subtitle = 'Agregar ';
    this.InitVariables();
    this.openVerticallyCentered(content);
  }


  EditFinancialEntity(_financialEntityModel: IFinancialEntityModel, content: any) {
    this.isUpdating = true;
    this.subtitle = "Edición de "
    this.financialEntityForm.setValue({
      FinancialEntityId: _financialEntityModel.FinancialEntityId,
      BankName: _financialEntityModel.BankName,
      BankTcCodeSale: _financialEntityModel.BankTcCodeSale,
      BankTcCodePurchase: _financialEntityModel.BankTcCodePurchase,
      Status: _financialEntityModel.Status,
    });
    this.openVerticallyCentered(content);
  }


  SaveFinancialEntity() {
    let _financialEntityId = this.financialEntityForm.value.FinancialEntityId;
    let _bankName = this.financialEntityForm.value.BankName;
    let _bankTcCodeSale = this.financialEntityForm.value.BankTcCodeSale;
    let _bankTcCodePurchase = this.financialEntityForm.value.BankTcCodePurchase;
    let _status = this.financialEntityForm.value.Status;

    let financialEntity: IFinancialEntityModel = {
      FinancialEntityId: _financialEntityId,
      BankName: _bankName,
      BankTcCodeSale: _bankTcCodeSale,
      BankTcCodePurchase: _bankTcCodePurchase,
      Status: _status
    }

    if (_financialEntityId === 0) {
      this.financialEntitiesService.CreateFinancialEntities(financialEntity).subscribe({
        next: (response: IFinancialEntityResponseModel) => {
          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(`Entidad financiera agregada correctamente`, ALERTS_ENUM.SUCCESS);
            this.InitVariables();
            this.closeModal();
            this.filteredFinancialEntities.length = 0;
          } else {
            this.alertService.ShowSmallCornerAlert(`Error al agregar la entidad financiera. ${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });
    } else {
      this.financialEntitiesService.UpdateFinancialEntities(financialEntity).subscribe({
        next: (response: IFinancialEntityResponseModel) => {
          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(`Entidad financiera actualizada correctamente`, ALERTS_ENUM.SUCCESS);
            this.InitVariables();
            this.closeModal();
          }
          else {
            this.alertService.ShowSmallCornerAlert(`Error al actualizar la entidad financiera. ${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });
    }
  }


  closeModal() {
    this.financialEntityForm.setValue({
      FinancialEntityId: 0,
      BankName: '',
      BankTcCodeSale: '',
      BankTcCodePurchase: '',
      Status: '',
    });
    this.modalService.dismissAll('');
  }

}