import { Component, OnInit } from '@angular/core';
import { IConnections, ALERTS_ENUM, ICompanyModel, IConnectionsResponseModel } from 'src/app/models';
import { ConnectionsService, AlertService, CompaniesService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-crud-connections',
  templateUrl: './crud-connections.component.html',
  styleUrls: ['./crud-connections.component.css']
})
export class CrudConnectionsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  connections: IConnections[] = [];
  filteredConnections: IConnections[] = [];
  searchForm: FormGroup; // formulario par alos filtros 
  status: boolean[] = [true, false];
  page = 1;
  pageSize = 10;
  collectionSize: number;

  connectionForm: FormGroup;
  isUpdating: boolean;
  subtitle: string;
  
  Companies: ICompanyModel[] = []; //lista compañias

  constructor(
    private ConectionService: ConnectionsService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private mCompanyService: CompaniesService

  ) {
  }

  ngOnInit() {
    this.onLoad();
  }

  onLoad() {
    this.searchForm = this.createSearchForm();
    this.connectionForm = this.createConnectionForm();
    this.loadInitialData();
  }

  // convenience getter for easy access to form fields
  get fConn() { return this.connectionForm.controls; }

  createSearchForm() {
    return this.formBuilder.group({
      Alias: [''],
      SapUser: ['']
    });
  }

  createConnectionForm() {
    return this.formBuilder.group({
      ConnId: [''],
      Alias: [''],
      SapUser: ['', Validators.required],
      SapPass: ['', Validators.required],
      TCUpdateURL: ['', Validators.required],
    });
  }

  // METODO QUE CARGA DATOS NICIALES(TRAE LAS COMPANYS,LOS SERVER DE SAPCONECTION)
  private loadInitialData() {
    this.connections.length = 0;
    // Servicio obtiene las conexiones de SAP
    let sapConn$ = this.ConectionService.GetConnections('', '');

    forkJoin(
      [sapConn$]
    ).subscribe((results) => {
      let sms: string = '';

      let _sapConnResp = results[0];
      // conexiones de SAP
      if (_sapConnResp) {
        if (_sapConnResp.Result) {
          this.connections = _sapConnResp.Connections;
          this.page = 1;
          this.pageChange();
        } else {
          sms = `${_sapConnResp.ErrorInfo.Message}`;
        }
      }

      if (sms.length > 0) {
        this.alertService.ShowSmallCornerAlert(`${sms}`, ALERTS_ENUM.ERROR);
      }
    });
  }

  // funcion para obtener la nueva lista de compañías para mostrar en el front
  // no recibe parametros
  pageChange() {
    this.collectionSize = Math.ceil(this.connections.length / this.pageSize) * this.pageSize;
    this.filteredConnections.length = 0;
    this.filteredConnections = this.connections.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }

  GetConnections(): void {
    this.filteredConnections.length = 0;
    this.connections.length = 0;
    let _alias = this.searchForm.controls['Alias'].value;
    let _sapUser = this.searchForm.controls['SapUser'].value;
    this.ConectionService.GetConnections(_alias, _sapUser)
      .subscribe({
        next: (res: IConnectionsResponseModel) => {
          if (res.Result) {
            this.connections = res.Connections;
            this.page = 1;
            this.pageChange();
          } else {
            this.alertService.ShowSmallCornerAlert(`${res.ErrorInfo.Message}`, ALERTS_ENUM.WARNING);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });
  }

  //Inizializa las variables por defecto
  InitVariables(): void {
    this.connections = [];
    this.filteredConnections = [];
    this.searchForm.setValue({
      Alias: '',
      SapUser: ''
    });
    this.LoadData();
  }

  //Carga una conexion para Editar
  getConnection(connection: IConnections, content: any) {

    this.isUpdating = true;
    this.subtitle = "Edición de ";
    this.connectionForm.setValue({
      ConnId: connection.ConnId,
      Alias: connection.Alias,
      SapUser: connection.SapUser,
      SapPass: connection.SapPass,
      TCUpdateURL: connection.TCUpdateURL,
    });
    this.openVerticallyCentered(content);
    // this.refreshConnections();
  }

  //Mostrar Modal para agregar nueva Conexion
  createConnection(content: any) {
    this.isUpdating = false;
    this.subtitle = 'Agregar ';
    this.connectionForm = this.createConnectionForm();
    this.openVerticallyCentered(content);
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, {
      size: 'xl',
      centered: true,
      backdrop: 'static'
    });
  }

  LoadData() {
    this.GetConnections();
    this.connectionForm = this.createConnectionForm();
  }

  //Crear o Actualizar una Conexion
  saveConection() {
    let connetion: IConnections = {
      ConnId: this.connectionForm.value.ConnId,
      Alias: this.connectionForm.value.Alias,
      SapUser: this.connectionForm.value.SapUser,
      SapPass: this.connectionForm.value.SapPass,
      TCUpdateURL: this.connectionForm.value.TCUpdateURL,
    }
    if (this.connectionForm.value.ConnId === "") {
      connetion.ConnId = 0;
      this.ConectionService.CreateConnection(connetion).subscribe({
        next: (response: IConnectionsResponseModel) => {
          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(` Conexión agregada correctamente!`, ALERTS_ENUM.SUCCESS);
            this.closeModal();
          } else {
            this.alertService.ShowSmallCornerAlert(` Error al agregar la conexión ${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });
    } else {
      this.ConectionService.updateConnection(connetion).subscribe({
        next: (response: IConnectionsResponseModel) => {
          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(`Conexión actualizada correctamente!`, ALERTS_ENUM.SUCCESS);
            this.closeModal();
          }
          else {
            this.alertService.ShowSmallCornerAlert(` Error al actualizar conexión ${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });
    }

  }

  //Cierra la modal
  closeModal() {
    this.InitVariables();
    this.modalService.dismissAll('');
  }

}
