export class AppConstants {
    // PRUEBAS DEBUG
    // public static get apiUrl(): string { return 'http://localhost:50101'; }
    // PRUEBAS TEST
    public static get apiUrl(): string { return 'https://tcapitest.clavisco.com'; }
    // PROD
    // public static get apiUrl(): string { return 'https://tcapi.clavisco.com'; }
}

export const ALERTS = [
    {
        Id: 1,
        name: 'success'
    },
    {
        Id: 2,
        name: 'error'
    },
    {
        Id: 3,
        name: 'warning'
    },
    {
        Id: 4,
        name: 'info'
    }
];

export enum ALERTS_ENUM {
    SUCCESS = 1,
    ERROR = 2,
    WARNING = 3,
    INFO = 4
}

export enum TcTypes {
    Venta = 1,
    Compra = 2,
    Promedio = 3
}

export interface EnumList {
    Value: number;
    Name: string;
}

export const TCStatus = [
    {
        id: 1,
        status: 'Actualizado',

    },
    {
        id: 2,
        status: 'Desactualizado',
    },
    {
        id: 3,
        status: 'En proceso',
    },
    {
        id: 4,
        status: 'Todos',
    }
];

export const SIZES = [
    {
        size: 15
    },
    {
        size: 20
    },
    {
        size: 25
    },
    {
        size: 30
    }
];
