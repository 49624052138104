
import { Component, OnInit } from '@angular/core';
import { IUsersByCompaniesAll, IUsersByCompanies, ALERTS_ENUM, ICompanyModel, IUsersByCompaniesAllResponseModel, IUsersByCompaniesResponseModel } from 'src/app/models';
import { UsersbycompaniesService, AlertService, CompaniesService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-crud-usersbycompanies',
  templateUrl: './crud-usersbycompanies.component.html',
  styleUrls: ['./crud-usersbycompanies.component.css']
})
export class CrudUsersbycompaniesComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  Userbycompanies: IUsersByCompaniesAll[] = [];//lista asignaciones
  filteredUserbycompanies: IUsersByCompaniesAll[] = [];//Lista filtra busqueda de asignaciones
  CompanyTypeaHead: any[] = []; // lista de compañias
  UserTypeaHead: any[] = []; // lista de usuarios
  searchForm: FormGroup; // formulario par alos filtros 
  page = 1; //Variables de paginacion
  pageSize = 10; //Variables de paginacion
  collectionSize: number;//Variables de paginacion
  isUpdating: boolean;
  subtitle: string;//Variable para asignar accion de asignacion
  Companies: ICompanyModel[] = []; //lista compañias
  Users: any[] = []; //lista usuarios
  getCompanyId: number;// variable obtener id de input typeahead
  getUserId: number;//variable obtener id de input typeahead

  // lista con las DB
  companiesDBCodeList: string[] = [];
  // lista con el nombre de las companias
  companiesNameList: string[] = [];

  UserbycompanieForm = this.formBuilder.group({
    UserByCompId: [''],
    CompanyId: [''],
    UserId: [''],
    DBCode: ['', Validators.required],
    UserName: ['', Validators.required]
  });

  constructor(
    private mCompanyService: CompaniesService,
    private UserbycompanieService: UsersbycompaniesService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.onLoad();
  }

  onLoad() {
    this.filteredUserbycompanies.length = 0;
    this.searchForm = this.createSearchForm();
    this.loadInitialData();
  }

  // convenience getter for easy access to form fields
  get fUserbycompany() { return this.UserbycompanieForm.controls; }

  createSearchForm() {
    return this.formBuilder.group({
      CompanyName: [''],
      CompanyDBCode: [''],
      UserName: ['']
    });
  }

  // METODO QUE CARGA DATOS NICIALES(TRAE LAS COMPANYS,LOS SERVER DE SAPCONECTION)
  private loadInitialData() {
    this.Userbycompanies.length = 0;
    this.Companies.length = 0;
    this.Users.length = 0;

    this.companiesDBCodeList.length = 0;
    this.companiesNameList.length = 0;

    // Servicio obtiene asignaciones
    let assignments$ = this.UserbycompanieService.GetAssignments('', '', '');
    // Servicio obtiene compañias
    let company$ = this.mCompanyService.GetCompanies('', '', 0, 2, '');
    // Servicio obtiene usuarios
    let users$ = this.UserbycompanieService.GetUsers();
    forkJoin(
      [assignments$, company$, users$]
    ).subscribe((results) => {
      let sms: string = '';

      let _assignmentsResp = results[0];
      // asignaciones
      if (_assignmentsResp) {
        if (_assignmentsResp.Result) {
          this.Userbycompanies = _assignmentsResp.Assignments;
          this.page = 1;
          this.pageChange();
        } else {
          sms = `${_assignmentsResp.ErrorInfo.Message}`;
        }
      }

      let _companyResp = results[1];
      // companias
      if (_companyResp) {
        if (_companyResp.Result) {
          this.Companies = _companyResp.CompaniesFincEntityList;
          this.companiesDBCodeList = _companyResp.CompaniesFincEntityList.map(x => x.DBCode);
          this.companiesNameList = _companyResp.CompaniesFincEntityList.map(x => x.DBName);
          this.GetCompaniesNames();
        } else {
          sms = `${_companyResp.ErrorInfo.Message}`;
        }
      }

      let _usersResp = results[2];
      // usuarios
      if (_usersResp) {
        if (_usersResp.Result) {
          this.Users = _usersResp.Users;
          this.GetUserNames();
        } else {
          sms = `${_usersResp.ErrorInfo.Message}`;
        }
      }

      if (sms.length > 0) {
        this.alertService.ShowSmallCornerAlert(`${sms}`, ALERTS_ENUM.ERROR);
      }
    });
  }

  // funcion para obtener la nueva lista de compañías para mostrar en el front
  // no recibe parametros
  pageChange() {
    this.collectionSize = Math.ceil(this.Userbycompanies.length / this.pageSize) * this.pageSize;
    this.filteredUserbycompanies.length = 0;
    this.filteredUserbycompanies = this.Userbycompanies.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }

  //inflar objeto de lista de Compañias
  GetCompaniesNames() {
    this.CompanyTypeaHead.length = 0;
    this.CompanyTypeaHead = this.Companies.map(x => x.DBCode);
  }

  //Inflar objeto con lista de nombres de usuarios
  GetUserNames() {
    this.UserTypeaHead.length = 0;
    this.UserTypeaHead = this.Users.map(x => x.UserName);
  }

  searchUsersByCompanies() {
    this.filteredUserbycompanies.length = 0;
    this.Userbycompanies.length = 0;
    let _getAll = false;
    let _user = this.searchForm.controls['UserName'].value;
    let _dbCode = this.searchForm.controls['CompanyDBCode'].value;
    let _compName = this.searchForm.controls['CompanyName'].value;

    if (_dbCode !== '' && !this.companiesDBCodeList.some(x => x === _dbCode)) {
      this.alertService.ShowSmallCornerAlert(`La base de datos seleccionada no es válida: ${_dbCode}`, ALERTS_ENUM.INFO);
      return;
    }

    if (_compName !== '' && !this.companiesNameList.some(x => x === _compName)) {
      this.alertService.ShowSmallCornerAlert(`El nombre de la compañía seleccionada no es válida: ${_compName}`, ALERTS_ENUM.INFO);
      return;
    }

    // if (_compName === '' && _dbCode === '' && _user === '') {
    //   _getAll = true;
    //   this.companiesDBCodeList.length = 0;
    //   this.companiesNameList.length = 0;
    // }

    this.UserbycompanieService.GetAssignments(_user, _dbCode, _compName)
      .subscribe({
        next: (res: IUsersByCompaniesAllResponseModel) => {
          if (res.Result) {
            this.Userbycompanies = res.Assignments;
            // this.collectionSize = Math.ceil(res.Assignments.length / this.pageSize) * this.pageSize;
            this.page = 1;
            this.pageChange();
          } else {
            this.alertService.ShowSmallCornerAlert(`${res.ErrorInfo.Message}`, ALERTS_ENUM.WARNING);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });

  }


  // funcion para el typeahead de company name
  searchCompName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.companiesNameList.filter(company => company.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  // funcion para el typeahead de dbcode
  searchDBCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.companiesDBCodeList.filter(company => company.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  selectedDBCode($event) {
    this.searchForm.controls['CompanyDBCode'].patchValue($event.item.DBCode);
  }

  selectedCompName($event) {
    this.searchForm.controls['CompanyName'].patchValue($event.item.DBName);
  }

  // funcion para el typeahead compañia
  searchCompany = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => (term === '' ? this.CompanyTypeaHead
        : this.CompanyTypeaHead.filter(v => v.toLowerCase().indexOf
          (term.toLowerCase()) > -1)).slice(0, 10))
    )

  // funcion para el typeahead de usuarios
  searchUser = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => (term === '' ? this.UserTypeaHead
        : this.UserTypeaHead.filter(v => v.toLowerCase().indexOf
          (term.toLowerCase()) > -1)).slice(0, 10))
    )

  //Mostrar Modal para agregar nueva Asignacion
  createUsersByCompanie(content: any) {
    this.isUpdating = false;
    this.subtitle = 'Agregar ';
    this.InitVariables();
    this.openVerticallyCentered(content);
  }

  //Inizializa las variables por defecto
  InitVariables(): void {

    this.UserbycompanieService.GetAssignments('', '', '').subscribe(e => {
      this.Userbycompanies = e.Assignments;
      this.page = 1;
      this.pageChange();
    });

    this.UserbycompanieForm.setValue({
      UserByCompId: '',
      CompanyId: '',
      UserId: '',
      DBCode: '',
      UserName: ''
    }
    );
    // this.searchForm.setValue({ searchInput: '' });
    // this.searchForm.reset();
    this.searchForm.setValue({
      CompanyName: '',
      CompanyDBCode: '',
      UserName: ''
    })
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  //Eliminar una asignacion de compañia por usuario
  DeleteUsersByCompanie(userbycompany: IUsersByCompaniesAll, content: any) {
    this.alertService.ConfirmationAlert(`Eliminar Asignación`, `Desea Eliminar ${userbycompany.UserName} de la Compañia ${userbycompany.DBCode}`
    ).then(v => {
      if (v.value == true) {
        this.UserbycompanieService.DeleteUserByCompany(userbycompany.UserByCompId).subscribe({
          next: (response: IUsersByCompaniesResponseModel) => {
            if (response.Result) {
              this.alertService.ShowSmallCornerAlert(` Asignacion Eliminada correctamente!!!`, ALERTS_ENUM.SUCCESS);
              this.closeModal();
              this.filteredUserbycompanies.length = 0;
              // this.refreshUsersByCompanie();
            } else {
              this.alertService.ShowSmallCornerAlert(` Error al Eliminar la Conexion!!! ${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
            }
          },
          error: (err: string) => {
            this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
          }
        });
      }
    });
  }
  //Carga una conexion para Editar
  getUsersByCompanie(userbycompany: IUsersByCompaniesAll, content: any) {
    this.isUpdating = true;
    this.subtitle = "Edición de "
    this.UserbycompanieForm.setValue({
      UserByCompId: userbycompany.UserByCompId,
      CompanyId: userbycompany.CompanyId,
      UserId: userbycompany.UserId,
      DBCode: userbycompany.DBCode,
      UserName: userbycompany.UserName

    });
    this.openVerticallyCentered(content);
    // this.refreshUsersByCompanie();
  }

  //Crear o Actualizar una Asignacion 
  saveUserbycompanie() {
    let _dbCode = this.UserbycompanieForm.value.DBCode;
    let _user = this.UserbycompanieForm.value.UserName;
    let _userByCompId = this.UserbycompanieForm.value.UserByCompId;


    let userbycompany: IUsersByCompanies = {
      UserByCompId: _userByCompId,
      CompanyId: this.GetCompanyId(_dbCode),
      UserId: this.GetUserId(_user),
    }

    if (_dbCode !== '' && !this.CompanyTypeaHead.some(x => x === _dbCode)) {
      this.alertService.ShowSmallCornerAlert(`La base de datos seleccionada no es válida: ${_dbCode}`, ALERTS_ENUM.INFO);
      return;
    }

    if (_user !== '' && !this.UserTypeaHead.some(x => x === _user)) {
      this.alertService.ShowSmallCornerAlert(`El usuario seleccionado no es válido: ${_user}`, ALERTS_ENUM.INFO);
      return;
    }

    if (_userByCompId === "") {
      userbycompany.UserByCompId = 0;
      this.UserbycompanieService.CreateUserByCompany(userbycompany).subscribe({
        next: (response: IUsersByCompaniesResponseModel) => {
          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(` Asignacion agregada correctamente!!!`, ALERTS_ENUM.SUCCESS);
            this.closeModal();
            this.filteredUserbycompanies.length = 0;
          } else {
            this.alertService.ShowSmallCornerAlert(` Error al agregar la Conexion!!!${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });
    } else {
      this.UserbycompanieService.UpdateteUserByCompany(userbycompany).subscribe({
        next: (response: IUsersByCompaniesResponseModel) => {
          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(` Conexion Actualizada correctamente!!!`, ALERTS_ENUM.SUCCESS);
            this.closeModal();
          }
          else {
            this.alertService.ShowSmallCornerAlert(` Error al Actualizar Conexion!!!${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
          }
        },
        error: (err: string) => {
          this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
        }
      });
    }
  }
  //Obtener id user del Input TypeaHead
  GetUserId(UserName: any): Number {
    this.Users.forEach(c => {
      if (c.UserName == UserName) {
        this.getUserId = c.UserId
      }
    });
    return this.getUserId;
  }
  //Obtener id company del Input TypeaHead
  GetCompanyId(company: any): Number {
    this.Companies.forEach(c => {
      if (c.DBCode == company) {
        this.getCompanyId = c.CompanyId
      }
    });
    return this.getCompanyId;
  }
  //Cierra la modal
  closeModal() {
    this.InitVariables();
    this.modalService.dismissAll('');
  }


}