import { Component, OnInit } from '@angular/core';

// SERVICES
import { AuthService } from 'src/app/services/index';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

  currUser: any;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(x => this.currUser = x);
  }

  LogOut() {
    this.authService.LogOut();
  }

}
