import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { UserIdModel } from '../models/index';

// SERVICIOS
import { StorageService } from './storage.service';

// MODELS

import { ICompanyModel } from '../models/companies';
import { CompaniesResponseModel, CompanyCurrencyResponseModel, ICompaniesResponseModel, ICompanyResponseModel, IConnectionResponseModel } from '../models/responses';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {

    constructor(
        private http: HttpClient,
        private storage: StorageService
    ) { }

    // se comunica con el API para obtener las compañias que tiene asignadas el usuario logeado
    GetCompaniesByUser() {

        const session = JSON.parse(this.storage.getItem('currUser'));

        const UserModel: UserIdModel = {
            UserId: Number(session.UserId)
        };

        return this.http.post<CompaniesResponseModel>(`${environment.ApiUrl}/api/Companies/GetCompaniesByUser`, UserModel);
    }

    // Obtener companias
    GetCompanies(CompanyName: string, CompanyDBCode: string, SapCon: number, Status: number, Currency: string) {
        return this.http.get<ICompaniesResponseModel>(`${environment.ApiUrl}/api/Companies/Get_Companies?CompanyName=${CompanyName}&CompanyDBCode=${CompanyDBCode}&SapCon=${SapCon}&Status=${Status}&Currency=${Currency}`);
    }

    // Obtener una compania
    GetCompany(_idCompany: number) {
        return this.http.get<ICompanyResponseModel>(`${environment.ApiUrl}/api/Companies/GetCompany?_idCompany=${_idCompany}`);
    }

    updateCompany(company: ICompanyModel) {
        return this.http.post<CompaniesResponseModel>(`${environment.ApiUrl}/api/Companies/UpdateCompanies`, company);
    }

    // Servicio para agregar una compania
    CreateCompany(company: ICompanyModel) {
        return this.http.post<CompaniesResponseModel>(`${environment.ApiUrl}/api/Companies/CreateCompany`, company);
    }

    // Servicio para obtener las conexiones para las companias
    GetSapConnection() {
        return this.http.get<IConnectionResponseModel>(`${environment.ApiUrl}/api/Companies/GetConnections`);
    }

    // Servicio obtiene las monedas de las compañias
    GetCompanyCurrencies() {
        return this.http.get<CompanyCurrencyResponseModel>(`${environment.ApiUrl}/api/Companies/GetCompanyCurrencies`);
    }
}
