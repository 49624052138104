import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IFinancialEntityModel } from "../models";
import { IFinancialEntityResponseModel } from "../models/responses";
import {environment} from "../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class FinancialEntitiesService {

    constructor(
        private http: HttpClient,
    ) { }

    GetFinancialEntities(Value: string, Status: string) {
        return this.http.get<IFinancialEntityResponseModel>(`${environment.ApiUrl}/api/FinancialEntities/GetFinancialEntities?Value=${Value}&Status=${Status}`);
    }

    GetFinancialEntitiesActive() {
        return this.http.get<IFinancialEntityResponseModel>(`${environment.ApiUrl}/api/FinancialEntities/GetFinancialEntitiesActive`);
    }

    CreateFinancialEntities(financialEntity: IFinancialEntityModel) {
        return this.http.post<IFinancialEntityResponseModel>(`${environment.ApiUrl}/api/FinancialEntities/CreateFinancialEntity`, financialEntity);
    }

    UpdateFinancialEntities(financialEntity: IFinancialEntityModel) {
        return this.http.post<IFinancialEntityResponseModel>(`${environment.ApiUrl}/api/FinancialEntities/UpdateFinancialEntity`, financialEntity);
    }

}
