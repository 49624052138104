import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgbModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// COMPONENTS
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { SidemenuComponent } from './components/shared/sidemenu/sidemenu.component';

// ROUTES
import { APP_ROUTING } from './app.routes';

// SERVICES
import { AuthService, AuthGuard } from './services';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LogsComponent } from './components/tc-logs/logs.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { CrudCompaniesComponent } from './components/crud-companies/crud-companies.component';
import { CrudConnectionsComponent } from './components/crud-connections/crud-connections.component';
import { CrudUsersbycompaniesComponent } from './components/crud-usersbycompanies/crud-usersbycompanies.component';
import { AppInterceptor } from './interceptors/app.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { CrudFinancialEntitiesComponent } from './components/crud-financial-entities/crud-financial-entities.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SidemenuComponent,
    LogsComponent,
    FooterComponent,
    CrudCompaniesComponent,
    CrudConnectionsComponent,
    CrudUsersbycompaniesComponent,
    CrudFinancialEntitiesComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTING,
    HttpClientModule,
    AngularFontAwesomeModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule.forRoot()
  ],
  providers: [
    AuthService,
    AuthGuard, 
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, 
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
