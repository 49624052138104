import { Component, OnInit } from '@angular/core';
import { ICompanyModel, ALERTS_ENUM, TCStatus, IConnections, ICompaniesResponseModel, IFinancialEntityModel, IFinancialEntityResponseModel, } from 'src/app/models';
import { CompaniesService, AlertService, ConnectionsService, FinancialEntitiesService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin, Observable } from 'rxjs';
import { CurrencyModel } from 'src/app/models/companies';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { EnumList, TcTypes } from '../../models/constants';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-crud-companies',
  templateUrl: './crud-companies.component.html',
  styleUrls: ['./crud-companies.component.css']
})

export class CrudCompaniesComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  companies: ICompanyModel[] = [];
  // lista con las DB
  companiesDBCodeList: string[] = [];
  // lista con el nombre de las companias
  companiesNameList: string[] = [];
  filteredCompanies: ICompanyModel[] = [];
  sapConList: IConnections[] = [];
  isUpdating: boolean;
  currencyList: CurrencyModel[] = [];
  financialEntitiesList: IFinancialEntityModel[] = [];

  status: boolean[] = [true, false];
  busy1: boolean;
  subtitle: string;
  EstadoCurrStatus: string;
  NxtUpdat: string;
  today = new Date();
  FechaAct = `${this.today.getDate()}-${this.today.getMonth() + 1}-${this.today.getFullYear()}`;

  FechaSig = new Date(this.today.setDate(this.today.getDate() + 1));
  FechaDiaSig = `${this.FechaSig.getDate()}-${this.FechaSig.getMonth() + 1}-${this.FechaSig.getFullYear()}`;
  page = 1;
  pageSize = 10;
  collectionSize: number;
  mTCStatus = TCStatus;

  companyForm: FormGroup;
  searchCompaniesForm: FormGroup;

  tcTypeList: EnumList[] = []

  constructor(
    private mCompanyService: CompaniesService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private fb: FormBuilder,
    private conectionService: ConnectionsService,
    private financialEntitiesService: FinancialEntitiesService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.onLoad();
  }

  // Inizializa las variables por defecto
  InitVariables(): void {
    this.companies.length = 0;
    this.companiesDBCodeList.length = 0;
    this.companiesNameList.length = 0;
    this.filteredCompanies.length = 0;
    this.subtitle = null;
    this.EstadoCurrStatus = null;
    this.NxtUpdat = null;
    this.tcTypeList = this.globalService.ConvertEnumIntoList(TcTypes);
    console.log(this.tcTypeList);
    this.searchCompaniesForm.setValue({
      CompanyName: '',
      CompanyDBCode: '',
      SapCon: '0',
      Active: 2,
      Currency: ''
    });
  }

  // funcion para el typeahead de company name
  searchCompName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.companiesNameList.filter(company => company.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  // funcion para el typeahead de dbcode
  searchDBCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.companiesDBCodeList.filter(company => company.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  // convenience getter for easy access to form fields
  get fComp() { return this.companyForm.controls; }

  selectedDBCode($event) {
    this.searchCompaniesForm.controls.CompanyDBCode.patchValue($event.item.DBCode);
  }

  selectedCompName($event) {
    this.searchCompaniesForm.controls.CompanyName.patchValue($event.item.DBName);
  }

  onLoad(): void {
    this.currencyList.length = 0;
    this.sapConList.length = 0;
    this.financialEntitiesList.length = 0
    this.searchCompaniesForm = this.createSearchCompaniesForm()
    this.ResetcompanyForm();
    this.loadInitialData();
    this.InitVariables();
  }

  // METODO QUE CARGA DATOS NICIALES(TRAE LAS COMPANYS,LOS SERVER DE SAPCONECTION)
  private loadInitialData() {
    this.companies.length = 0;
    this.companiesDBCodeList.length = 0;
    this.companiesNameList.length = 0;
    this.sapConList.length = 0;

    // Servicio obtiene compañias
    const company$ = this.mCompanyService.GetCompanies('', '', 0, 2, '');
    // Servicio obtiene usuarios
    const sapCon$ = this.conectionService.GetConnections('', '');
    // Servicio obtiene las monedas de las compañias
    const companyCurrency$ = this.mCompanyService.GetCompanyCurrencies();

    const financialEntities$ = this.financialEntitiesService.GetFinancialEntitiesActive()
    forkJoin(
      [company$, sapCon$, companyCurrency$, financialEntities$]
    ).subscribe((results) => {
      let sms: string = '';

      let _companyResp = results[0];
      // companias
      if (_companyResp) {
        if (_companyResp.Result) {
          this.companies = _companyResp.CompaniesFincEntityList;
          this.companiesDBCodeList = _companyResp.CompaniesFincEntityList.map(x => x.DBCode);
          this.companiesNameList = _companyResp.CompaniesFincEntityList.map(x => x.DBName);
          this.page = 1;
          this.pageChange();
        } else {
          sms = `${_companyResp.ErrorInfo.Message}`;
        }
      }

      let _sapConResp = results[1];
      // usuarios
      if (_sapConResp) {
        if (_sapConResp.Result) {
          this.sapConList = _sapConResp.Connections;
          this.searchCompaniesForm.patchValue({
            SapCon: 0
          });
        } else {
          sms = `${_sapConResp.ErrorInfo.Message}`;
        }
      }

      let _compCurrResp = results[2];
      // monedas
      if (_compCurrResp) {
        if (_compCurrResp.Result) {
          this.currencyList = _compCurrResp.CurrencyList;
        } else {
          sms = `${_compCurrResp.ErrorInfo.Message}`;
        }
      }

      let _financEnt = results[3];
      // entidades financieras
      if (_financEnt) {
        if (_financEnt.Result) {
          this.financialEntitiesList = _financEnt.FinancialEntities
        } else {
          sms = `${_financEnt.ErrorInfo.Message}`;
        }
      }

      if (sms.length > 0) {
        this.alertService.ShowSmallCornerAlert(`${sms}`, ALERTS_ENUM.ERROR);
      }
    });
  }

  createSearchCompaniesForm() {
    return this.fb.group({
      CompanyName: [''],
      CompanyDBCode: [''],
      SapCon: [''],
      Active: [2],
      Currency: ['']
    });
  }

  GetCompanies(): void {
    this.companies.length = 0;
    this.filteredCompanies.length = 0;

    let _getAll = false;
    let _compName = this.searchCompaniesForm.controls.CompanyName.value;
    let _dbCode = this.searchCompaniesForm.controls.CompanyDBCode.value;
    let _sapCon = this.searchCompaniesForm.controls.SapCon.value;
    let _active = this.searchCompaniesForm.controls.Active.value;
    let _currency = this.searchCompaniesForm.controls.Currency.value;

    if (_dbCode !== '' && !this.companiesDBCodeList.some(x => x === _dbCode)) {
      this.alertService.ShowSmallCornerAlert(`La base de datos seleccionada no es válida: ${_dbCode}`, ALERTS_ENUM.INFO);
      return;
    }

    if (_compName !== '' && !this.companiesNameList.some(x => x === _compName)) {
      this.alertService.ShowSmallCornerAlert(`El nombre de la compañía seleccionada no es válida: ${_compName}`, ALERTS_ENUM.INFO);
      return;
    }

    if (_compName === '' && _dbCode === '' && _sapCon === '0' && _active === 2 && _currency === '') {
      _getAll = true;
      this.companiesDBCodeList.length = 0;
      this.companiesNameList.length = 0;
    }
    this.mCompanyService.GetCompanies(_compName, _dbCode, _sapCon, parseInt(_active), _currency).subscribe({
      next: (next: ICompaniesResponseModel) => {
        if (next.Result) {
          this.companies = next.CompaniesFincEntityList;
          if (_getAll) {
            this.companiesDBCodeList = next.CompaniesFincEntityList.map(x => x.DBCode);
            this.companiesNameList = next.CompaniesFincEntityList.map(x => x.DBName);
          }
          this.page = 1;
          this.pageChange();
          this.alertService.ShowSmallCornerAlert(`Compañías cargadas correctamente!!!`, ALERTS_ENUM.SUCCESS);
        } else {
          this.alertService.ShowSmallCornerAlert(`Error cargando las compañías ${next.ErrorInfo.Message}`, ALERTS_ENUM.WARNING);
        }
      },
      error: (err: string) => {
        this.alertService.ShowSmallCornerAlert(`${err}`, ALERTS_ENUM.ERROR);
      },
    });
  }

  // funcion para obtener la nueva lista de compañías para mostrar en el front
  // no recibe parametros
  pageChange() {
    this.collectionSize = Math.ceil(this.companies.length / this.pageSize) * this.pageSize;
    this.filteredCompanies.length = 0;
    this.filteredCompanies = this.companies.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
  }

  ResetcompanyForm(): void {
    this.companyForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      companyName: ['', Validators.required],
      SAPconnectionId: ['', Validators.required],
      busy: [''],
      currentStatus: [''],
      nextStatus: [''],
      isActive: [''],
      companyCurrency: [''],
      UseBCCRRate: [true],
      DocRateTolerance: [{ value: 0, disabled: true }],
      FinancialEntity: ['', Validators.required],
      TcUsed: ['', Validators.required]
    });
  }

  CurrStatusEstado(company) {
    let CurrStatusString = new Date(company.CurrUpdate);
    let FechaCurr = `${CurrStatusString.getDate()}-${CurrStatusString.getMonth() + 1}-${CurrStatusString.getFullYear()}`;
    if (FechaCurr == this.FechaAct) {
      this.EstadoCurrStatus = 'Actualizado';
    } else {
      this.EstadoCurrStatus = 'Desactualizado';
    }
    return this.EstadoCurrStatus;
  }
  NxtUpdateEstado(company) {
    let NxtUpdateString = new Date(company.NxtUpdate);
    let FechaNxtUpdate = `${NxtUpdateString.getDate()}-${NxtUpdateString.getMonth() + 1}-${NxtUpdateString.getFullYear()}`;

    if (FechaNxtUpdate == this.FechaDiaSig) {
      this.NxtUpdat = 'Actualizado';
    } else {
      this.NxtUpdat = 'Desactualizado';
    }
    return this.NxtUpdat;
  }

  // Obtiene una compania
  getCompany(company: ICompanyModel, content: any) {
    this.isUpdating = true;
    this.subtitle = 'Edición de ';
    const useBCCRRate = (company.DocRateTolerance === 0) ? true : false;
    this.companyForm.setValue({
      id: company.CompanyId,
      name: company.DBCode,
      companyName: company.DBName,
      SAPconnectionId: company.SAPConnectionId,
      busy: company.Busy ? 'Sí' : 'No',
      currentStatus: this.CurrStatusEstado(company),
      nextStatus: this.NxtUpdateEstado(company),
      isActive: company.Active,
      companyCurrency: company.Currency,
      UseBCCRRate: useBCCRRate,
      DocRateTolerance: company.DocRateTolerance,
      FinancialEntity: company.FinancialEntityId,
      TcUsed: company.TcUsed
    });
    if (useBCCRRate) {
      this.companyForm.controls.DocRateTolerance.setValue(0);
      this.companyForm.controls.DocRateTolerance.disable();
    } else {
      this.companyForm.controls.DocRateTolerance.enable();
    }
    this.openVerticallyCentered(content);
  }

  // Abre la modal y limpia los campos
  createCompany(content: any) {
    this.isUpdating = false;
    this.subtitle = 'Agregar ';
    this.ResetcompanyForm();
    this.openVerticallyCentered(content);
  }
  // Crea una compania
  saveCompany() {
    let mCompany: ICompanyModel = {
      CompanyId: this.companyForm.value.id,
      DBCode: this.companyForm.value.name,
      SAPConnectionId: this.companyForm.value.SAPconnectionId,
      Active: this.companyForm.value.isActive,
      DBName: this.companyForm.value.companyName,
      Busy: this.busy1,
      CurrStatus: 2,
      CurrLastAttempt: '2020-07-07 00:00:00.000',
      CurrUpdate: '2020-07-05 00:00:00.000',
      NxtStatus: '1',
      NxtLastAttempt: '2020-07-06 00:00:00.000',
      NxtUpdate: '2020-07-05 00:00:00.000',
      Currency: this.companyForm.value.companyCurrency,
      DocRateTolerance: this.companyForm.getRawValue().DocRateTolerance,
      FinancialEntityId: this.companyForm.value.FinancialEntity,
      TcUsed: this.companyForm.value.TcUsed
    };

    if (this.companyForm.value.id === '') {

      this.mCompanyService.CreateCompany(mCompany).subscribe(response => {

        if (response.Result) {
          this.closeModal();
          this.alertService.ShowSmallCornerAlert(` Compañía agregada correctamente`, ALERTS_ENUM.SUCCESS);
        } else {
          this.alertService.ShowSmallCornerAlert(` Error al agregar la compañía. ${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
        }
      });
    } else {
      this.mCompanyService.updateCompany(mCompany).subscribe(response => {

        if (response.Result) {
          this.alertService.ShowSmallCornerAlert(` Compañía actualizada correctamente`, ALERTS_ENUM.SUCCESS);
          this.closeModal();
        } else {
          this.alertService.ShowSmallCornerAlert(`Error al actualizar la compañía. ${response.ErrorInfo.Message}`, ALERTS_ENUM.ERROR);
        }
      });

    }
  }

  // Abre la modal en el centro de la pantalla
  openVerticallyCentered(content) {
    if (this.isUpdating) {
      this.companyForm.get('isActive').setValidators([Validators.required]);
      this.companyForm.get('isActive').updateValueAndValidity();
    } else {
      this.companyForm.get('isActive').clearValidators();
      this.companyForm.get('isActive').updateValueAndValidity();
    }

    this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
  }

  // Cierra la modal
  closeModal() {
    this.InitVariables();
    this.GetCompanies();
    this.modalService.dismissAll('');
  }

  onUseBCCRRateChange(event) {
    if (event.target.checked) {
      this.companyForm.controls.DocRateTolerance.setValue(0);
      this.companyForm.controls.DocRateTolerance.disable();
    } else {
      this.companyForm.controls.DocRateTolerance.enable();
    }
  }

}
