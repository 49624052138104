import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

// SERVICIOS
import { StorageService } from './storage.service';

// MODELS
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient,
    private router: Router,
    private storage: StorageService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }

  LogIn(email: string, password: string) {

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', email)
      .set('password', password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(`${environment.ApiUrl}/token`, body.toString(), { headers })
      .pipe(map(user => {
        // login successful if there's a owin token in the response
        if (user && user.access_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.storage.setItem(user, 'currUser');
          this.currentUserSubject.next(user);
        }
        return user;
      }));

  }

  LogOut() {
    // remove user from local storage to log user out
    this.storage.removeItem('currUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  // Verificacion si un usuario esta logueado y el token no ha expirado aun
  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    // const TOKEN = this.currentUserValue;
    const TOKEN = JSON.parse(this.storage.getItem('currUser'));

    if (TOKEN) {
      var expires = new Date(TOKEN.ExpireTime);
      var today = new Date();
      if (today <= expires) { // posee token y el mismo aun no expira, es valido
        return true;
      }
    }
    return false;
  }

}
